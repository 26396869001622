.nsw-container {
  max-width: 75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.nsw-container--flush {
  padding-left: 0;
  padding-right: 0;
}
.nsw-container .nsw-container,
.ui-dialog-off-canvas .nsw-container {
  padding-left: 0;
  padding-right: 0;
}
.section-links {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (min-width: 48em) {
  .section-links {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.section-links a {
  color: #002664;
  text-decoration: none;
}
.section-links a:hover {
  background-color: rgba(0, 133, 179, 0.2);
  outline: 2px solid rgba(0, 133, 179, 0.2);
}
.section-links a:focus {
  outline: 3px solid #0085b3;
}
.section-links a:active {
  background-color: #0085b3;
  color: #fff;
}
.section-links__group {
  padding-bottom: 1.5rem;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  min-width: 16rem;
  max-width: 20%;
}
@media (min-width: 48em) {
  .section-links__group {
    flex-basis: 50%;
    min-width: auto;
  }
}
@media (min-width: 62em) {
  .section-links__group {
    flex-basis: 25%;
  }
}
.section-links__heading {
  margin: 0;
  font-family: Montserrat, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
}
.section-links__list {
  padding: 0;
  list-style: none;
  margin: 0.25rem 0 0;
  max-width: 95%;
}
.section-links__item:not(:first-child) {
  margin-top: 0.5rem;
}
.section-links__heading {
  font-size: 1.17rem;
  margin-bottom: 1.25rem;
}
.nsw-footer {
  font-family: Montserrat, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  border-top: 1px solid #a0a5ae;
}
.nsw-footer hr {
  margin: 1rem 0;
  height: 1px;
  border: 0;
  color: #a0a5ae;
  background-color: #a0a5ae;
}
.nsw-footer__upper {
  padding-top: 1.5rem;
  background-color: #fff;
}
.nsw-footer__lower {
  padding: 1rem 0 1.5rem;
  background-color: #333;
  color: #fff;
  font-size: 0.75rem;
}
.nsw-footer__lower a {
  color: #fff;
}
.nsw-footer__lower a:focus {
  outline: 3px solid #fff;
  outline-offset: 3px;
}
@media (min-width: 48em) {
  .nsw-footer__info {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
  }
}
.nsw-footer__copyright {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.nsw-footer__built {
  -webkit-box-flex: 0;
  flex: 0 0 12.5rem;
}
.nsw-footer__built a:hover {
  text-decoration: none;
}
@media (min-width: 48em) {
  .nsw-footer__built {
    text-align: right;
  }
}
.nsw-footer-links {
  font-family: Montserrat, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
}
.nsw-footer-links__item {
  margin: 0 0 1rem;
  -webkit-box-flex: 1;
  flex: 1 0 50%;
  max-width: 50%;
}
@media (min-width: 48em) {
  .nsw-footer-links__item {
    margin-right: 1.5rem;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    max-width: none;
  }
}
.nsw-footer-links__link {
  text-decoration: none;
}
.nsw-footer-links__link:hover {
  text-decoration: underline;
}
.nsw-footer-links__link:focus {
  outline: 3px solid #fff;
  outline-offset: 3px;
}
.page__footer {
  margin: 3rem 0 0 !important;
}
.nsw-footer {
  border-top: none;
}

.section-links__item a {
  font-size: 16px;
}

.is-external:after {
  content: '';
  display: inline-block;
  position: static;
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='%23202D60'%3E%3Cpath d='M30,0v15h-3V5.1L8.9,23.2l-2.1-2.1L24.9,3H15V0H30z M7.9,0v3H3v24h24v-4.9h3V30H0V0H7.9z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  margin-left: 5px;
}
.is-external.nsw-card__link:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.is-external.nsw-card__link:after {
  width: 12px;
  height: 12px;
}
.is-external.nsw-link-list__link:after {
  width: 10px;
  height: 10px;
  margin-left: 0;
}
.is-external.nsw-hero-banner__link:after {
  width: 16px;
  height: 16px;
}
.dcs-person .nsw-link-list__icon {
  top: calc(50% - 0.5rem);
}
.dcs-person .is-external.nsw-link-list__link {
  padding-right: 2rem;
}
.dcs-person .is-external.nsw-link-list__link .nsw-link-list__icon {
  right: 1rem;
  top: calc(50% - 0.5rem);
}
.nsw-card--headline .nsw-card__link.is-external:after {
  width: 16px;
  height: 16px;
}
.nsw-button.is-external:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='%23fff'%3E%3Cpath d='M30,0v15h-3V5.1L8.9,23.2l-2.1-2.1L24.9,3H15V0H30z M7.9,0v3H3v24h24v-4.9h3V30H0V0H7.9z'/%3E%3C/svg%3E");
}
.nsw-button.is-external.nsw-button--outline:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='%23202D60'%3E%3Cpath d='M30,0v15h-3V5.1L8.9,23.2l-2.1-2.1L24.9,3H15V0H30z M7.9,0v3H3v24h24v-4.9h3V30H0V0H7.9z'/%3E%3C/svg%3E");
}
.nsw-button.is-external.nsw-button--outline:hover:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='%23fff'%3E%3Cpath d='M30,0v15h-3V5.1L8.9,23.2l-2.1-2.1L24.9,3H15V0H30z M7.9,0v3H3v24h24v-4.9h3V30H0V0H7.9z'/%3E%3C/svg%3E");
}
